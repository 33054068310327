import { styled } from 'styled-components';

import { mq } from '@/styles/media.styled';

export const Wrapper = styled.div`
    margin-top: 5.75rem;
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 280px;

    ${mq.lt.lg} {
        margin-top: 48px;
        height: 100%;
    }

    .profit-section-title {
        font-weight: 500;
        font-size: ${({ theme }) => theme.fontSize.xl};
        line-height: 120%;

        ${mq.lt.lg} {
            font-size: ${({ theme }) => theme.fontSize.xl};
        }
    }

    .profit-cards-list-section {
        margin-top: 2rem;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        gap: 32px;

        ${mq.lt.lg} {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            gap: 1rem;
            width: 100%;
        }
    }
`;
