import React, { FC, ReactNode } from 'react';

import * as Styled from './styled';

type TProps = {
    cardText: string;
    cardTextSize?: 'medium' | 'small' | 'full' | 'wide';
    className?: string;
    titleIndex: string | number;

    titleText: string | ReactNode;
};

export const StepInfoCard: FC<TProps> = (props) => {
    const { cardText, cardTextSize = 'full', className, titleIndex, titleText } = props;

    return (
        <Styled.Wrapper className={className}>
            <div className="card-title-section">
                <span className="card-index">{titleIndex}</span>

                <div className="card-title">{titleText}</div>
            </div>

            <div className={`card-text ${cardTextSize}`}>
                <span>{cardText}</span>
            </div>
        </Styled.Wrapper>
    );
};
