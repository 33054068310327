import { TReadParamsProperties, ResourceService } from '@/shared/api';
import { axiosSiteClient } from '@/shared/api/site';

import { TProductCard } from '../types';

const Index = ResourceService('cards', axiosSiteClient);

export const getProductCards = async (): Promise<TProductCard[]> => {
    const response = await Index.read<{ data: TProductCard[] }, TReadParamsProperties>();
    return response.data;
};
