import { AxiosError } from 'axios';

import { TReadParamsProperties, ResourceService } from '@/shared/api';
import { axiosSiteClient } from '@/shared/api/site';
import { NOT_FOUND_PAGE, SERVER_ERROR, STATUS_CODE_OK } from '@/shared/constants/settings';
import { customCaptureException } from '@/shared/lib/custom-capture-exception';

import { TSupportQuestion, TFaqQuestionsRes } from '../types';

export const FaqService = ResourceService('faqs', axiosSiteClient);

export const getMainPageFaqQuestions = async (): Promise<TFaqQuestionsRes> => {
    let statusCode = STATUS_CODE_OK;

    try {
        const responseData = await FaqService.read<{ data: TSupportQuestion[] }, TReadParamsProperties>({
            params: { mainpage: true },
        });

        if (responseData?.data) {
            return {
                data: responseData.data.sort((prev, act) => (prev.position < act.position ? -1 : 1)),
                statusCode,
            };
        }

        statusCode = NOT_FOUND_PAGE;
    } catch (err) {
        customCaptureException(err, 'getMainPageFaqQuestions');

        const error = err as AxiosError;
        statusCode = error.response?.status === NOT_FOUND_PAGE ? NOT_FOUND_PAGE : SERVER_ERROR;
    }

    return {
        data: [],
        statusCode,
    };
};
