import { styled } from 'styled-components';

export const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
    padding: 12px 16px;
    width: 235px;
    height: 293px;
    background: ${({ theme }) => theme.color.secondary};
    box-shadow: 0 4px 12px rgb(0 0 0 / 8%);
    border-radius: 12px;
    margin-bottom: 1rem;

    .product-image {
        max-width: 200px;
        height: 176px;

        img {
            object-fit: contain;
        }
    }

    .product-title {
        font-weight: 500;
        font-size: ${({ theme }) => theme.fontSize.sm};
        line-height: 120%;
    }

    .product-price {
        display: flex;
        align-items: center;
        gap: 4px;
        font-weight: 500;
        font-size: ${({ theme }) => theme.fontSize.xl};
        background: linear-gradient(218.13deg, #63d07b 18.93%, #0bb6ba 94.11%);
        background-clip: text;
        -webkit-text-fill-color: transparent;
        line-height: 180%;
    }

    .price-text {
        font-weight: 400;
        font-size: ${({ theme }) => theme.fontSize.xs};
        line-height: 120%;
    }
`;
