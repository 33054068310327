import { customCaptureException } from '@/shared/lib/custom-capture-exception';
import { TMetaData } from '@/shared/types/types';

import { TReadParamsProperties, ResourceService } from '../baseServices/resource-service';

import { axiosSiteClient } from './axios';

export const MetadataService = ResourceService('metadata', axiosSiteClient);

export const getMetaDataPage = async (pageUrl: string, locale: string): Promise<TMetaData | null> => {
    try {
        const response = await MetadataService.read<{ data: TMetaData[] }, TReadParamsProperties>({
            params: {
                itemsPerPage: 1,
                locale,
                page: 1,
                page_url: pageUrl,
            },
        });
        return response.data[0];
    } catch (e) {
        customCaptureException(e, 'getMetaDataPage');

        return null;
    }
};
