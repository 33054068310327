import Head from 'next/head';
import React, { FC } from 'react';

import { TMetaData } from '@/shared/types/types';

type TProps = {
    metadata: TMetaData | null;
};

export const HeadMeta: FC<TProps> = ({ metadata }) => {
    return (
        <Head>
            <title>{metadata?.title}</title>

            <meta content={metadata?.description} name="description" />
            {metadata?.keywords && <meta content={metadata.keywords} name="keywords" />}
        </Head>
    );
};
