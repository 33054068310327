import { styled } from 'styled-components';

import { mq } from '@/styles/media.styled';

export const StyledStepCardsList = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    gap: 2.625rem;
    padding: 0;
    margin-top: 113px;
    height: 200px;

    ${mq.lt.xl} {
        height: 250px;
    }

    ${mq.lt.lg} {
        display: flex;
        flex-direction: column;
        gap: 1rem;
        margin-top: 57px;
        height: 100%;
    }
`;
