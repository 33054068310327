import { styled } from 'styled-components';

import { mq } from '@/styles/media.styled';

export const Wrapper = styled.div`
    position: relative;
    margin: auto 0;
    width: 100%;
    display: flex;
    justify-content: space-between;
    height: 100%;
    ${mq.lt.md} {
        flex-direction: column-reverse;
    }
`;

export const TitleWrapper = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 48px 0;
    height: 100%;
    width: 50%;

    ${mq.lt.md} {
        padding: 16px 0;
        width: 100%;
    }

    .bold-title {
        font-weight: 700;
        font-size: ${({ theme }) => theme.fontSize.xxl};
        line-height: 120%;

        ${mq.lt.lg} {
            font-size: ${({ theme }) => theme.fontSize.lg};
        }
    }

    .normal-title {
        font-weight: 400;
        font-size: ${({ theme }) => theme.fontSize.xl};
        line-height: 120%;
        width: 100%;
        max-width: 560px;
        margin-top: 1rem;

        & .text-brand-green {
            font-weight: 500;
        }

        ${mq.lt.lg} {
            font-size: ${({ theme }) => theme.fontSize.sm};
        }
    }

    .text-brand-green {
        color: ${({ theme }) => theme.color.primary};
    }

    .brand-button-link {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: start;
        margin-top: 3rem;

        ${mq.lt.lg} {
            margin-top: 2rem;
            width: 100%;
        }

        .button-sizing {
            ${mq.lt.lg} {
                width: 100%;
            }
        }
    }

    .brand-button-link-title {
        font-weight: 500;
        font-size: ${({ theme }) => theme.fontSize.lg};
        line-height: 120%;
        padding: 8px 18px;

        ${mq.lt.md} {
            font-size: ${({ theme }) => theme.fontSize.sm};
            width: 100%;
        }
    }

    .max-w-md {
        max-width: 583px;
    }
`;

export const ImageWrapper = styled.div`
    width: 50%;
    position: relative;
    ${mq.lt.md} {
        width: 100%;
        height: 400px;
    }
    ${mq.lt.sm} {
        width: 100%;
        height: 60vw;
    }

    img {
        object-fit: contain;
        ${mq.lt.sm} {
            object-position: left;
        }
    }
`;
