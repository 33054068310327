import { styled } from 'styled-components';

import { mq } from '@/styles/media.styled';

export const Wrapper = styled.div`
    padding-top: 107px;
    display: flex;
    flex-direction: column;
    width: 100%;

    ${mq.lt.lg} {
        padding-top: 52px;
    }

    .slider-title {
        font-weight: 500;
        font-size: ${({ theme }) => theme.fontSize.xl};
        line-height: 120%;

        ${mq.lt.lg} {
            font-size: ${({ theme }) => theme.fontSize.lg};
        }

        .text-brand-green {
            color: ${({ theme }) => theme.color.primary};
        }

        .spacing-xr-xxs {
            margin-right: 0.37rem;
        }
    }

    .slider-section {
        padding-top: 2rem;
        width: 100%;
    }
`;
