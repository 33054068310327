import { styled } from 'styled-components';

import { mq } from '@/styles/media.styled';

export const Wrapper = styled.section`
    position: relative;

    .swiper-slide {
        width: 235px !important;

        &:last-of-type {
            margin-right: 0 !important;
        }
    }
`;

export const StyledProductSliderNavButton = styled.button`
    position: absolute;
    background-color: ${({ theme }) => theme.color.secondary};
    border: none;
    border-radius: 50%;
    box-shadow: 0 2px 12px rgb(0 0 0 / 12%);
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 42px;
    height: 42px;
    top: 50%;
    margin-top: -21px;
    z-index: 10;

    &.swiper-button-disabled {
        display: none;
    }

    ${mq.lt.md} {
        display: none;
    }
`;

export const PrevNavButtonWrapper = styled(StyledProductSliderNavButton)`
    left: -20px;

    svg {
        transform: rotate(180deg);
    }
`;

export const NextNavButtonWrapper = styled(StyledProductSliderNavButton)`
    right: -20px;
`;

export const SwiperContainer = styled.div`
    margin: 0 auto;
    display: flex;
    justify-content: center;
    overflow: hidden;
`;
