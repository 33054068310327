import React, { FC } from 'react';

import * as Styled from './styled';

type TProps = {
    className?: string;
    contentText: string;
    contentTitle: string;
    lineThroughTitle: string;
    title: string;
};

const ProfitInfoCard: FC<TProps> = (props) => {
    const { className, contentText, contentTitle, lineThroughTitle, title } = props;

    return (
        <Styled.Wrapper className={className}>
            <div className="line-through-title">{lineThroughTitle}</div>

            <div className="card-title">{title}</div>

            <div className="card-body-main-text">{contentTitle}</div>

            <div className="card-body">{contentText}</div>
        </Styled.Wrapper>
    );
};

export default ProfitInfoCard;
