import React, { FC } from 'react';

import { useTranslate } from '@/shared/hooks/use-translate';

import { TProductCard } from '../../types';
import { ProductsSlider } from '../products-slider';

import * as Styled from './styled';

type TProps = {
    items: TProductCard[];
};

export const TechnicalProducts: FC<TProps> = ({ items }) => {
    const { t } = useTranslate();

    return (
        <Styled.Wrapper>
            <div className="slider-title">
                <span className="spacing-xr-xxs">
                    {t('messageSliderTitle', {
                        span: (chunks: string) => <span className="text-brand-green">{chunks}</span>,
                    })}
                </span>
            </div>

            <div className="slider-section">
                <ProductsSlider items={items} />
            </div>
        </Styled.Wrapper>
    );
};
