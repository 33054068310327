import React, { FC } from 'react';

import { PUBLIC_PHONE_NUMBER } from '@/shared/config/app';
import { useTranslate } from '@/shared/hooks/use-translate';
import { TStepInfoCards } from '@/shared/types/types';
import { StepInfoCard } from '@/shared/ui/step-info-card';

import { StyledStepCardsList } from './styled';

type TProps = {
    phone: string | undefined;
    first_number_text: string | undefined;
};

export const CardsInfo: FC<TProps> = ({ phone, first_number_text }) => {
    const { t } = useTranslate();
    const _phone = (phone ?? PUBLIC_PHONE_NUMBER) as string;

    const stepInfoCardsList: TStepInfoCards[] = [
        {
            step: 1,
            text: t('stepInfoCardText_item1', {
                a: (chunks: string) => (
                    <a className="font-bold text-main-color" href={`tel:+${_phone.replace(/[^0-9]/g, '')}`}>
                        {chunks}
                    </a>
                ),
                phone: _phone,
            }),
            title: first_number_text ? (
                <span dangerouslySetInnerHTML={{ __html: first_number_text }} />
            ) : (
                t('stepInfoCardTitle_item1')
            ),
        },
        {
            step: 2,
            text: t('stepInfoCardText_item2'),
            title: t('stepInfoCardTitle_item2'),
        },
        {
            step: 3,
            text: t('stepInfoCardText_item3'),
            title: t('stepInfoCardTitle_item3'),
            titleSize: 'small',
        },
    ];

    return (
        <StyledStepCardsList>
            {stepInfoCardsList.map((card) => (
                <StepInfoCard
                    cardText={card.text}
                    cardTextSize={card.cardTextSize}
                    key={card.step}
                    titleIndex={card.step}
                    titleText={card.title}
                />
            ))}
        </StyledStepCardsList>
    );
};
