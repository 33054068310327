import React, { FC, useState } from 'react';

import ArrowRightIcon from '@/shared/icons/arrow-right-icon';

import * as Styled from './styled';

type TProps = {
    questionDescription?: string;
    questionTitle: string;
};

export const SupportQuestionCard: FC<TProps> = (props) => {
    const { questionDescription, questionTitle } = props;

    const [showQuestionText, setShowQuestionText] = useState<boolean>(false);

    return (
        <Styled.Wrapper $isOpen={showQuestionText} onClick={() => setShowQuestionText(!showQuestionText)}>
            <div className="question-content">
                <div className="question-title">{questionTitle}</div>

                <div className="question-arrow-icon">
                    <ArrowRightIcon />
                </div>
            </div>

            {questionDescription && showQuestionText && (
                <div className="question-text" dangerouslySetInnerHTML={{ __html: questionDescription }} />
            )}
        </Styled.Wrapper>
    );
};
