import { styled } from 'styled-components';

import { mq } from '@/styles/media.styled';

export const Wrapper = styled.button<{ $isOpen: boolean }>`
    display: flex;
    flex-direction: column;
    align-items: center;
    cursor: pointer;
    padding: 16px 24px;
    gap: 10px;
    height: ${(isOpen) => (isOpen ? 'max-content' : 'min-content')};
    width: 100%;

    ${mq.lt.xxl} {
        width: 100%;
        max-width: 100%;
    }

    background: ${({ theme }) => theme.color.secondary};
    box-shadow: 0 4px 12px rgb(0 0 0 / 8%);
    border-radius: 12px;
    border: none;
    transition: 300ms !important;

    &:hover {
        color: ${({ theme }) => theme.color.dark};
    }

    .question-content {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        gap: 10px;
        width: 100%;
        height: 58px;
    }

    .question-title {
        font-weight: 400;
        font-size: 16px;
        line-height: 120%;
        color: #000;
        text-align: start;
    }

    .question-arrow-icon {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        transform: ${({ $isOpen }) => ($isOpen ? 'rotate(90deg)' : 'rotate(0deg)')};
        height: 100%;
    }

    .question-text {
        font-weight: 400;
        font-size: 14px;
        line-height: 120%;
        display: flex;
        flex-direction: column;
        align-items: start;
        text-align: start;
        width: 100%;
        color: #000;

        strong {
            font-weight: 700;
        }
    }
`;
