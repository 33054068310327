import { styled } from 'styled-components';

import { mq } from '@/styles/media.styled';

export const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: start;
    padding: 16px 24px;
    gap: 12px;
    width: 100%;
    height: 100%;
    background: ${({ theme }) => theme.color.secondary};
    box-shadow: 0 4px 12px rgb(0 0 0 / 8%);
    border-radius: 12px;

    .card-index {
        height: 96px;
        font-weight: 500;
        font-size: 80px;
        line-height: 120%;
        margin-right: 1.5rem;
        background: linear-gradient(218.13deg, #63d07b 18.93%, #0bb6ba 94.11%);
        background-clip: text;
        -webkit-text-fill-color: transparent;

        ${mq.lt.lg} {
            height: 100%;
            font-size: 60px;
        }
    }

    .card-title {
        font-weight: 500;
        font-size: ${({ theme }) => theme.fontSize.lg};
        line-height: 120%;

        ${mq.lt.lg} {
            font-size: ${({ theme }) => theme.fontSize.md};
        }
    }

    .medium {
        width: 100%;
        max-width: 262px;
    }

    .small {
        width: 100%;
        max-width: 240px;
    }

    .wide {
        width: 100%;
        max-width: 335px;
    }

    .full {
        width: 100%;
    }

    .card-text {
        font-weight: 400;
        font-size: ${({ theme }) => theme.fontSize.md};
        line-height: 19.2px;

        ${mq.lt.lg} {
            font-size: ${({ theme }) => theme.fontSize.sm};
        }
    }

    .font-bold {
        font-weight: 700;
    }

    .text-main-color {
        background: linear-gradient(218.13deg, #63d07b 18.93%, #0bb6ba 94.11%);
        background-clip: text;
        -webkit-text-fill-color: transparent;
    }

    .card-title-section {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
    }
`;
