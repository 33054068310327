import React, { FC } from 'react';
import { Navigation } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';

import { ArrowIcon } from '@/shared/icons/arrow-icon';
import TechnicCard from '@/shared/ui/technic-card';

import { TProductCard } from '../../types';

import * as Styled from './styled';

import 'swiper/css';
import 'swiper/css/navigation';

const breakPoints = {
    1350: {
        slidesPerView: 5,
        spaceBetween: 20,
    },
};

type TProps = {
    id?: string;
    items: TProductCard[];
};

export const ProductsSlider: FC<TProps> = ({ items, id }) => {
    const classNextSwipeButton = id ? `product-swiper-button-next-${id}` : 'product-swiper-button-next';
    const classPrevSwipeButton = id ? `product-swiper-button-prev-${id}` : 'product-swiper-button-prev';

    return (
        <Styled.Wrapper>
            <Styled.SwiperContainer>
                <Swiper
                    breakpoints={breakPoints}
                    modules={[Navigation]}
                    navigation={{
                        nextEl: `.${classNextSwipeButton}`,
                        prevEl: `.${classPrevSwipeButton}`,
                    }}
                    slidesPerView="auto"
                    spaceBetween={20}
                    freeMode={true}
                >
                    {items.map((product, index) => {
                        const { estimate, imageUrl, name } = product;
                        return (
                            <SwiperSlide key={index}>
                                <TechnicCard estimate={estimate} imageUrl={imageUrl} name={name} />
                            </SwiperSlide>
                        );
                    })}
                </Swiper>
            </Styled.SwiperContainer>
            <Styled.PrevNavButtonWrapper className={classPrevSwipeButton}>
                <ArrowIcon />
            </Styled.PrevNavButtonWrapper>
            <Styled.NextNavButtonWrapper className={classNextSwipeButton}>
                <ArrowIcon />
            </Styled.NextNavButtonWrapper>
        </Styled.Wrapper>
    );
};
