import React from 'react';

import { TSvgIcon } from '@/shared/types/types';

export const ArrowIcon: TSvgIcon = (props): JSX.Element => {
    const { color = '#A9ADBC', width = 8 } = props;

    return (
        <svg fill={color} viewBox="0 0 8 12" width={width} xmlns="http://www.w3.org/2000/svg">
            <path d="M1.29 9.87998L5.17 5.99998L1.29 2.11998C0.899998 1.72998 0.899998 1.09998 1.29 0.70998C1.68 0.31998 2.31 0.31998 2.7 0.70998L7.29 5.29998C7.68 5.68998 7.68 6.31998 7.29 6.70998L2.7 11.3C2.31 11.69 1.68 11.69 1.29 11.3C0.909998 10.91 0.899998 10.27 1.29 9.87998Z" />
        </svg>
    );
};
