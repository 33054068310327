import { styled } from 'styled-components';

import { mq } from '@/styles/media.styled';

export const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    height: 100%;
    margin-top: 100px;
    margin-bottom: 100px;

    ${mq.lt.md} {
        margin-top: 60px;
        margin-bottom: 0;
    }

    .w-full {
        width: 100%;
    }

    .h-full {
        height: 100%;
    }

    .spacing-xr-xxs {
        margin-right: 0.37rem;
    }

    .estimate-layout {
        margin-top: 112px;
    }
`;
