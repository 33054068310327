import { FC } from 'react';

import * as Styled from './styled';

type TProps = {
    text: string;
};

export const DynamicSeoTextBlock: FC<TProps> = ({ text }) => {
    return (
        <Styled.Wrapper>
            <div className="text text-dynamic-content" dangerouslySetInnerHTML={{ __html: text }} />
        </Styled.Wrapper>
    );
};
