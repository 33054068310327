import { styled } from 'styled-components';

export const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: start;
    padding: 16px 24px;
    gap: 12px;
    width: 100%;
    height: 100%;
    background: ${({ theme }) => theme.color.secondary};
    box-shadow: 0 4px 12px rgb(0 0 0 / 8%);
    border-radius: 12px;

    div {
        font-weight: 400;
        line-height: 120%;
    }

    .line-through-title {
        font-size: ${({ theme }) => theme.fontSize.md};
        text-decoration-line: line-through;
        color: ${({ theme }) => theme.color.neutral300};
    }

    .card-title {
        font-size: ${({ theme }) => theme.fontSize.xxl};
        color: ${({ theme }) => theme.color.primary};
    }

    .card-body-main-text {
        font-size: ${({ theme }) => theme.fontSize.lg};
    }

    .card-body {
        font-size: ${({ theme }) => theme.fontSize.sm};
    }
`;
