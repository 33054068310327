import React from 'react';

import { TSvgIcon } from '@/shared/types/types';

const ArrowRightIcon: TSvgIcon = (props) => {
    const { color = '#D3D5DB', height = 20, width = 11 } = props;

    return (
        <svg fill={color} height={height} viewBox="0 0 11 20" width={width} xmlns="http://www.w3.org/2000/svg">
            <path
                d="M0.37999 19.01C0.86999 19.5 1.65999 19.5 2.14999 19.01L10.46 10.7C10.85 10.31 10.85 9.68 10.46 9.29L2.14999 0.980003C1.65999 0.490003 0.86999 0.490003 0.37999 0.980003C-0.11001 1.47 -0.11001 2.26 0.37999 2.75L7.61999 10L0.36999 17.25C-0.11001 17.73 -0.11001 18.53 0.37999 19.01Z"
                fill={color}
            />
        </svg>
    );
};

export default ArrowRightIcon;
