import React, { FC } from 'react';

import { useTranslate } from '@/shared/hooks/use-translate';
import ProfitInfoCard from '@/shared/ui/profit-info-card';

import * as Styled from './styled';

export const ProfitCards: FC = () => {
    const { t } = useTranslate();

    const profitInfoCardsList = [
        {
            contentText: t('profitInfoCard1_content'),
            contentTitle: t('Максимальный срок пользования микрокредитом'),
            id: 1,
            lineThroughTitle: t('60 дней для золота'),
            title: t('90 дней для золота'),
        },
        {
            contentText: t('profitInfoCard2_content'),
            contentTitle: t('Быстрое одобрение и оформление микрокредита'),
            id: 2,
            lineThroughTitle: t('1 час'),
            title: t('5 минут'),
        },
        {
            contentText: t('profitInfoCard3_content'),
            contentTitle: t('Минимальная сумма для погашения основного долга'),
            id: 3,
            lineThroughTitle: '1000 ₸',
            title: '1 ₸',
        },
    ];

    return (
        <Styled.Wrapper>
            <span className="profit-section-title">{t('profitCardsListTitle')}</span>

            <div className="profit-cards-list-section">
                {profitInfoCardsList.map((card) => (
                    <ProfitInfoCard
                        contentText={card.contentText}
                        contentTitle={card.contentTitle}
                        key={card.id}
                        lineThroughTitle={card.lineThroughTitle}
                        title={card.title}
                    />
                ))}
            </div>
        </Styled.Wrapper>
    );
};
