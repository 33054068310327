import { styled } from 'styled-components';

import { mq } from '@/styles/media.styled';

export const Wrapper = styled.div`
    margin-top: 111px;
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;

    ${mq.lt.lg} {
        margin-top: 52px;
    }

    .mt-16 {
        margin-top: 16px;
    }

    .support-section-content {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        width: 100%;

        ${mq.lt.md} {
            display: flex;
            flex-direction: column;
        }
    }

    .support-questions {
        display: flex;
        flex-direction: column;
        margin-top: 16px;
        gap: 24px;

        ${mq.lt.lg} {
            max-width: 930px;
        }

        ${mq.lt.xl} {
            height: 100%;
            width: 100%;
            gap: 0;
        }
    }

    .support-section-title {
        font-weight: 500;
        font-size: ${({ theme }) => theme.fontSize.xl};
        line-height: 120%;
    }

    .support-questions-list {
        column-count: 2;
        width: 100%;

        ${mq.lt.xl} {
            column-count: 1;
        }

        .question-item {
            page-break-inside: avoid;
            width: 100%;
            margin-bottom: 16px;

            &:last-child {
                margin-bottom: 0;
            }
        }
    }

    .brand-question-button-link {
        width: 100%;
        height: 64px;

        ${mq.lt.xl} {
            margin-top: 32px;
        }

        .bqb-link-content {
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: center;
            height: 100%;
            width: 100%;
            font-weight: 500;
            font-size: ${({ theme }) => theme.fontSize.lg};
            line-height: 120%;
        }
    }

    .question-banner-image {
        display: flex;
        flex-direction: row;
        justify-content: end;
        width: 100%;
        max-width: 420px;
        margin-right: 24px;

        ${mq.lt.xxl} {
            display: none;
        }
    }
`;
