import Image from 'next/image';
import React, { FC, ReactNode } from 'react';

import SupportQuestionImg from '@/public/support-question.png';
import { useAppDispatch, showModal } from '@/store';

import { useTranslate } from '@/shared/hooks/use-translate';
import { cleanSpaces } from '@/shared/lib/string';
import { Button } from '@/shared/ui/button';

import { TSupportQuestion } from '../../types';
import { SupportQuestionCard } from '../support-question-card';

import * as Styled from './styled';

type TProps = {
    items: TSupportQuestion[];
    form: ReactNode;
};

export const SupportQuestions: FC<TProps> = ({ items, form }) => {
    const { t } = useTranslate();
    const dispatch = useAppDispatch();

    const handleShowModalPhone = () => {
        dispatch(
            showModal({
                content: form,
                size: 'Rubber',
                type: 'Unboarded',
            }),
        );
    };

    return (
        <Styled.Wrapper>
            <div className="support-section-title">{t('Часто задаваемые вопросы')}</div>

            <div className="support-section-content">
                <div className="support-questions">
                    <div className="support-questions-list mt-16">
                        {items.map((faq) => (
                            <div className="question-item" key={faq.position}>
                                <SupportQuestionCard
                                    questionDescription={cleanSpaces(faq.answer)}
                                    questionTitle={cleanSpaces(faq.question)}
                                />
                            </div>
                        ))}
                    </div>

                    <div className="support-questions-list">
                        <div className="brand-question-button-link">
                            <Button onClick={handleShowModalPhone} stretch variant="gradient-brand-green">
                                <div className="support-section-title bqb-link-content">{t('Задать вопрос')}</div>
                            </Button>
                        </div>
                    </div>
                </div>

                <div className="question-banner-image">
                    <Image alt="Support question" height={381} src={SupportQuestionImg} />
                </div>
            </div>
        </Styled.Wrapper>
    );
};
