import React, { FC } from 'react';

import { EstimateForm, EPawnshopCategoryType } from '@/features/estimate-form';
import { OrderCallForm } from '@/features/order-call-form';

import { DepartmentsInfoBlock } from '@/entities/department';
import { SupportQuestions, TSupportQuestion } from '@/entities/faq';
import { TDynamicPage } from '@/entities/pages';
import { TechnicalProducts, TProductCard } from '@/entities/product';

import { LayoutDefault } from '@/shared/ui/layout/default';

import { Banner } from './banner';
import { CardsInfo } from './cards-info';
import { DynamicSeoTextBlock } from './dynamic-seo-text-block';
import { ProfitCards } from './profit-cards';
import * as Styled from './styled';

export type TProps = {
    page?: TDynamicPage;
    products: TProductCard[];
    faq: TSupportQuestion[];
};

export const MainInfoBlocks: FC<TProps> = ({ products, faq, page }) => {
    return (
        <Styled.Wrapper>
            <LayoutDefault.Content>
                <Banner dynamicPage={page} />
                <CardsInfo phone={page?.phone_number} first_number_text={page?.first_number_text} />
                <div className="estimate-layout">
                    <EstimateForm
                        activeTabCalc={
                            (page?.calculator_type as EPawnshopCategoryType) ?? EPawnshopCategoryType.APPLIANCE
                        }
                        dynamicPage={page}
                    />
                </div>
                {(!page || page.show_estimations) && <TechnicalProducts items={products} />}
                <ProfitCards />
                {page && page.seo_text?.content && <DynamicSeoTextBlock text={page.seo_text?.content} />}
                <SupportQuestions items={faq} form={<OrderCallForm />} />
                <DepartmentsInfoBlock />
            </LayoutDefault.Content>
        </Styled.Wrapper>
    );
};
