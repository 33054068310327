import Image from 'next/image';
import React, { FC } from 'react';

import { useTranslate } from '@/shared/hooks/use-translate';
import { formatCurrency } from '@/shared/lib/currency';

import * as Styled from './styled';

type TProps = {
    estimate: string | number;
    imageUrl: string;
    name: string;
};

const TechnicCard: FC<TProps> = (props) => {
    const { estimate, imageUrl, name } = props;

    const { t } = useTranslate();

    return (
        <Styled.Wrapper>
            <div className="product-image">
                <Image alt={name} height={176} src={imageUrl} width={200} />
            </div>

            <div className="product-title">{name}</div>

            <div className="price-text">{t('Вы получите на руки')}</div>

            <div className="product-price">
                <div className="price-num">{formatCurrency(estimate)}</div> ₸
            </div>
        </Styled.Wrapper>
    );
};

export default TechnicCard;
